import PublicNavbar from "./PublicNavbar";
import { PropsWithChildren } from "react";

export default function OnboardingLayout(props: PropsWithChildren<any>) {
  return (
    <div className="flex min-h-screen w-96 mx-auto items-center justify-center">
      <div className="w-full">
        <div>
          <p className="mb-5 text-center font-title text-4xl">Courtbase</p>
        </div>
        <div>{props.children}</div>
      </div>
    </div>
  );
}
