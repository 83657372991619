import { Menu, Transition } from "@headlessui/react";
import { ButtonHTMLAttributes, Fragment, PropsWithChildren } from "react";

interface DropdownMenuProps {
  button: JSX.Element;
  items?: { title: string; onClick?: () => void }[];
  children?: JSX.Element;
}
export const DropdownMenu = ({
  button,
  items = [],
  children,
}: DropdownMenuProps) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button>{button}</Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {items?.map((item) => (
              <MenuItem key={`item-${item.title}`}>{item.title}</MenuItem>
            ))}
            {children}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export const MenuItem = ({
  children,
  ...props
}: PropsWithChildren<ButtonHTMLAttributes<any>>) => {
  return (
    <button
      {...props}
      className={
        "group flex space-x-2 w-full items-center bg-white px-2 py-2 text-sm text-gray-700 hover:bg-gray-50"
      }
    >
      {children}
    </button>
  );
};
