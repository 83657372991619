// src/pages/_app.tsx
import "../styles/globals.css";
import type { AppProps } from "next/app";
import "cal-sans";

import AppLayout from "../components/layout/AppLayout";
import { trpc } from "../utils/trpc";
import { ReactElement, ReactNode, useEffect } from "react";
import { NextPage } from "next";
import { Inter } from "next/font/google";
import { ToastContainer, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import ModalContainer from "react-modal-promise";
import AppUserProvider from "../components/providers/AppUserProvider";
import PublicLayout from "../components/layout/PublicLayout";
import Head from "next/head";
import favicon from "../assets/favicona2.png";
import OnboardingLayout from "../components/layout/OnboardingLayout";
import { appWithTranslation, useTranslation } from "next-i18next";
import { Crisp } from "crisp-sdk-web";
import {
  ClerkProvider,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
} from "@clerk/nextjs";
import Script from "next/script";

export type NextPageWithLayout<P = Record<string, never>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const transition = cssTransition({
  enter: "fade-in",
  exit: "fade-out",
});

const inter = Inter({ subsets: ["latin"] });

const privatePages = ["/dashboard/", "/onboarding/", "/user/"];

function App({ Component, pageProps, router }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page);
  const isDashboard = router.pathname.startsWith("/dashboard");
  const isOnboarding = router.pathname.startsWith("/onboarding");
  const isAuth = router.pathname.startsWith("/auth");

  const isPrivatePage =
    privatePages.filter((p) => router.pathname.startsWith(p)).length > 0;

  const { t } = useTranslation();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      Crisp.configure("e52cfb95-a753-4145-9de5-04723e2d62ce");
    }
  }, []);

  const Layout = (): JSX.Element => {
    if (isDashboard) {
      return <AppLayout>{getLayout(<Component {...pageProps} />)}</AppLayout>;
    }
    if (isOnboarding) {
      return (
        <OnboardingLayout>
          {getLayout(<Component {...pageProps} />)}
        </OnboardingLayout>
      );
    }
    if (isAuth) {
      return <>{getLayout(<Component {...pageProps} />)}</>;
    }

    return (
      <PublicLayout>{getLayout(<Component {...pageProps} />)}</PublicLayout>
    );
  };

  return (
    <>
      <Script
        strategy="afterInteractive"
        id="gtm"
      >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-M96SW62');`}</Script>
      <Head>
        <title key="title">Courtbase</title>
        <meta
          property="description"
          content={t("Online tennis court booking platform") as string}
        />
        <meta itemProp="name" content="Courtbase" />
        <meta
          itemProp="description"
          content="Online tennis court booking platform"
        />
        <meta itemProp="image" content="https://courtbase.co/meta.png" />
        <meta property="og:url" content="https://courtbase.co" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Courtbase" />
        <meta
          property="og:description"
          content="Online tennis court booking platform"
        />
        <meta property="og:image" content="https://courtbase.co/meta.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Courtbase" />
        <meta
          name="twitter:description"
          content="Online tennis court booking platform"
        />
        <meta name="twitter:image" content="https://courtbase.co/meta.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="icon" type="image/x-icon" href={favicon.src} />
      </Head>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-M96SW62"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      <ClerkProvider
        appearance={{
          elements: {
            formFieldInput:
              "rounded-lg focus:ring-0 focus:border-primary-500 border-gray-300",
            card: "shadow-none border border-gray-200",
          },
          variables: {
            colorPrimary: "#5FCEB6",
          },
        }}
        {...pageProps}
      >
        <AppUserProvider>
          <ModalContainer />
          <main className={`${inter.className} font-sans antialiased`}>
            {isPrivatePage ? (
              <>
                <SignedIn>
                  <Layout />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn redirectUrl={router.asPath} />
                </SignedOut>
              </>
            ) : (
              <Layout />
            )}
            <ToastContainer
              autoClose={4000}
              position="bottom-right"
              pauseOnFocusLoss={false}
              transition={transition}
              hideProgressBar={true}
              toastClassName={(ctx) =>
                classNames(
                  ctx?.defaultClassName,
                  "!font-medium text-sm !text-gray-900 font-sans rounded-sm shadow-lg !ring-black !ring-1 !ring-opacity-5 border-b-4",
                  ctx?.type === "success" && "border-green-500",
                  ctx?.type === "warning" && "border-orange-500",
                  ctx?.type === "error" && "border-red-500",
                )
              }
              icon={(ctx) => {
                switch (ctx.type) {
                  case "success": {
                    return (
                      <CheckCircleIcon
                        height={20}
                        width={20}
                        className="text-green-400"
                      />
                    );
                  }
                  case "warning": {
                    return (
                      <ExclamationTriangleIcon
                        height={20}
                        width={20}
                        className="text-orange-400"
                      />
                    );
                  }
                  case "error": {
                    return (
                      <ExclamationCircleIcon
                        height={20}
                        width={20}
                        className="text-red-400"
                      />
                    );
                  }
                }
              }}
            />
          </main>
        </AppUserProvider>
      </ClerkProvider>
    </>
  );
}

export default trpc.withTRPC(appWithTranslation(App));
