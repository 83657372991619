import { Organization } from "@acme/db";
import { atom, useAtom } from "jotai";

export const organizationAtom = atom<Organization | null>(null);

export function useOrganization() {
  const [organization, setOrganization] = useAtom(organizationAtom);

  return { organization, setOrganization };
}
