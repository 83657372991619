import { PropsWithChildren, useEffect } from "react";
import { trpc } from "../../utils/trpc";
import { useAuth, useClerk } from "@clerk/nextjs";
import { useAtom } from "jotai";
import { userAtom } from "../../state/user";
import { organizationAtom } from "../../state/organization";
import { contextLoadingAtom } from "../../state/context";

const AppUserProvider = ({ children }: PropsWithChildren<any>) => {
  const { userId, isLoaded } = useAuth();

  const [, setUserProfile] = useAtom(userAtom);
  const [, setOrganization] = useAtom(organizationAtom);
  const [, setLoading] = useAtom(contextLoadingAtom);

  const { refetch } = trpc.public.getContext.useQuery(undefined, {
    enabled: !!userId,
    onSuccess: (data) => {
      if (data?.user?.id) {
        setUserProfile(data.user);
      }
      if (data?.ownedOrganizations?.length) {
        setOrganization(data?.ownedOrganizations[0] || null);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    setLoading(true);
    if (userId && isLoaded) {
      setLoading(true);
      refetch();
    } else if (isLoaded && !userId) {
      setLoading(false);
    }
  }, [userId]);

  return children;
};

export const useAppUser = () => {
  const { signOut: signOutClerk } = useClerk();
  const [userProfile, setUserProfile] = useAtom(userAtom);
  const [organization] = useAtom(organizationAtom);
  const [loading] = useAtom(contextLoadingAtom);
  const trpcUtils = trpc.useContext();

  const signOut = async () => {
    await signOutClerk();
    trpcUtils.public.getContext.invalidate();
    setUserProfile(null);
  };

  return {
    signOut,
    user: userProfile,
    organization: organization,
    loading,
  };
};

export default AppUserProvider;
