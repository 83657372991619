import { PropsWithChildren, useEffect } from "react";
import Navbar from "./Navbar";
import { useAppUser } from "../providers/AppUserProvider";
import { useClerk } from "@clerk/nextjs";
import { useRouter } from "next/router";
import { use } from "i18next";
import Loading from "./Loading";

export default function AppLayout({ children }: PropsWithChildren) {
  const { user, loading } = useAppUser();
  const router = useRouter();

  if (loading) {
    return <div className="h-screen w-screen flex justify-center items-center">
      <p className="font-title text-4xl mr-4">Courtbase</p>
      <Loading/>
    </div>
  }

  if (!user) {
    return null;
  }

  return (
    <>
      <Navbar />
      <div className="">{children}</div>
    </>
  );
}
