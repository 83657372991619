import PublicNavbar from "./PublicNavbar";
import { PropsWithChildren } from "react";
import Footer from "./Footer";

export default function PublicLayout(props: PropsWithChildren<any>) {
  return (
    <div className="flex min-h-screen flex-col justify-between">
      <div>
        <PublicNavbar />
        <div className="pt-5 pb-10 md:pt-8">{props.children}</div>
      </div>
      <Footer />
    </div>
  );
}
