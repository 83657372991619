import PageWidthLayout from "./PageWidthLayout";
import Image from "next/image";
import dlAppStore from "../../assets/download_appstore.svg";
import dlPlayStore from "../../assets/download_play.png";
import NextLink from "next/link";

export default function Footer() {
  return <div className="py-10 border-t bg-gray-50/50">
    <PageWidthLayout>
      <div className="grid md:grid-cols-3 gap-5">
        <div>
          <p className="font-title text-2xl">Courtbase</p>
          <div className="flex items-center space-x-2">
            <a href="https://apps.apple.com/us/app/courtbase/id6445848369">
              <Image
                src={dlAppStore.src}
                height={dlAppStore.height}
                width={120}
                alt="Download Courtbase on App store"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=io.gn1.courtbase">
              <Image
                src={dlPlayStore.src}
                height={dlPlayStore.height}
                width={150}
                alt="Download Courtbase on Play store"
              />
            </a>
          </div>
        </div>
        <div className="flex flex-col space-y-2 text-sm">
          <NextLink href="/for-organizations">For organizations</NextLink>
          <NextLink href="/">For players</NextLink>
          <NextLink href="/courts">Courts</NextLink>
        </div>
        <div className="flex flex-col space-y-2 text-sm">
          <NextLink href="/terms-and-conditions" rel="canonical">Terms and conditions</NextLink>
          <NextLink href="/privacy-policy" rel="canonical">Privacy policy</NextLink>
        </div>
      </div>
    </PageWidthLayout>
  </div>
}
