import NextLink from "next/link";
import { useClerk, useUser } from "@clerk/nextjs";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { DropdownMenu, MenuItem } from "../general/DropdownMenu";
import { useRouter } from "next/router";
import classNames from "classnames";
import { useAppUser } from "../providers/AppUserProvider";
import Avatar from "../general/Avatar";
import logo from '../../assets/icon23.png';

export default function Navbar() {
  const router = useRouter();
  const { organization, user, loading } = useAppUser();
  const { pathname } = useRouter();
  const { signOut } = useClerk();

  const navItems = [
    {
      title: "Urnik",
      path: "/dashboard/schedule",
      parent: "schedule",
    },
    {
      title: "Rezervacije",
      path: "/dashboard/reservations",
      parent: "reservations",
    },
    {
      title: "Igralci",
      path: "/dashboard/players",
      parent: "players",
    },
    {
      title: "Nastavitve",
      path: "/dashboard/settings/general",
      parent: "settings",
    },
  ];

  return (
    <div>
      <div>
        <div
          className={classNames(
            "flex items-center p-4",
            !user && "border-b border-gray-200",
          )}
        >
          <img src={logo.src} alt="Courtbase Logo" className="h-10 w-10" />
          {organization ? (
            <>
              <span className="mx-4 text-xl font-thin text-gray-300">/</span>
              <div className="flex items-center">
                <BuildingOfficeIcon className="h-4 w-4 text-gray-600" />
                <span className="ml-2 text-sm font-medium text-gray-700">
                  {organization?.name}
                </span>
                <span className="ml-2 rounded-full bg-primary-500 px-2 py-0.5 text-xs uppercase text-white">
                  Premium
                </span>
              </div>
            </>
          ) : null}
          <div className="ml-auto flex items-center space-x-3">
            <div>
              <NextLink
                className="flex items-center rounded-md border border-gray-200 py-1 px-2 text-sm text-gray-500"
                href={"/"}
              >
                Docs
              </NextLink>
            </div>
            {!user && !loading && (
              <a
                className="text-sm font-medium text-primary-500"
                href="/auth/login"
              >
                Vpis
              </a>
            )}
            {user && !loading && (
              <DropdownMenu
                button={
                  <Avatar
                    user={user}
                  />
                }
              >
                <MenuItem
                  onClick={() => {
                    signOut();
                    router.push("/");
                  }}
                >
                  Logout
                </MenuItem>
              </DropdownMenu>
            )}
          </div>
        </div>
        {user && !loading && organization && (
          <div className="relative flex items-center space-x-4 border-b border-gray-200 px-4 pb-3">
            <>
              {navItems.map((item) => (
                <NextLink
                  key={item.path}
                  className={classNames(
                    "text-sm",
                    pathname.indexOf(item.parent) !== -1 &&
                      "relative transform text-primary-500 after:absolute after:-bottom-3 after:left-1/2 after:block after:h-0.5 after:w-full after:-translate-x-1/2 after:rounded after:bg-primary-500 after:content-['']",
                    pathname.indexOf(item.parent) === -1 &&
                      "text-gray-500 hover:text-gray-700",
                  )}
                  href={item.path}
                >
                  {item.title}
                </NextLink>
              ))}
            </>
          </div>
        )}
      </div>
    </div>
  );
}
