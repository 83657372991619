import React from "react";
import classNames from "classnames";
import { UserProfile } from "@acme/db";

interface AvatarProps {
  user: Partial<UserProfile> | null;
  size?: "xsmall"| "small" | "medium" | "large";
}
export default function Avatar({ user, size }: AvatarProps) {
  const getInitials = () => {
    if (user?.firstName?.length || user?.lastName?.length) {
      if (user?.firstName?.length && user?.lastName?.length) {
        return `${user?.firstName[0]}${user?.lastName[0]}`;
      }
      return `${user?.firstName || ""}${user?.lastName || ""}`.slice(0, 2);
    }
    if (user?.email) {
      return user?.email.slice(0, 2);
    }
    return "AA";
  };

  if (user?.avatarUrl) {
    return (
      <img
        src={user?.avatarUrl}
        className={classNames(
          `rounded-full`,
          size === "xsmall" && "h-4 w-4",
          size === "small" && "h-6 w-6",
          (size === "medium" || !size) && "h-10 w-10",
          size === "large" && "h-12 w-12",
        )}
      />
    );
  }

  return (
    <div
      className={classNames(
        "flex items-center justify-center rounded-full bg-primary-500",
        size === "xsmall" && "h-4 w-4",
        size === "small" && "h-6 w-6",
        (size === "medium" || !size) && "h-10 w-10",
      )}
      style={{
        fontSize: size === "xsmall" ? "0.5rem" : "1rem",
      }}
    >
      <span
        className={classNames(
          "uppercase tracking-tighter text-white",
          size === "small" && "text-xs",
        )}
      >
        {getInitials()}
      </span>
    </div>
  );
}
