import NextLink from "next/link";
import { useRouter } from "next/router";
import classNames from "classnames";
import { useAppUser } from "../providers/AppUserProvider";
import { MenuItem } from "../general/DropdownMenu";
import {
  AdjustmentsHorizontalIcon,
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import React, { Fragment } from "react";
import { useTranslation } from "next-i18next";
import { GB, SI } from "country-flag-icons/react/3x2";
import { useUser } from "@clerk/nextjs";
import Avatar from "../general/Avatar";
import logo from "../../assets/icon23.png";
import { Disclosure, Menu, Transition } from "@headlessui/react";
export default function PublicNavbar() {
  const { pathname, asPath } = useRouter();
  const { user, organization, signOut, loading } = useAppUser();
  const { user: aUser } = useUser();
  const { t, i18n } = useTranslation(["marketing"]);

  const links = [
    {
      href: "/",
      label: t("for-players"),
    },
    {
      href: "/for-organizations",
      label: t("for-organizations"),
    },
    {
      href: "/courts",
      label: t("courts"),
    },
  ];

  const handleLogout = () => {
    signOut();

  }

  const userLinks = [
    {
      href: "/user/bookings",
      label: t("my-bookings"),
    },
    {
      href: "/user/settings",
      label: t("settings"),
    },
  ];

  return (
    <>
      <Disclosure
        as="nav"
        className="sticky left-0 top-0 border-b border-gray-200 bg-white py-2 z-20"
      >
        {({ open }) => (
          <>
            <div className="px-2 md:px-10">
              <div className="flex justify-between md:block items-center">
                <div className="flex w-full items-center">
                  <div className="flex items-center">
                    <NextLink
                      href="/"
                      className="mr-5 flex items-center tracking-wide"
                    >
                      <img
                        src={logo.src}
                        style={{ height: 50, width: 50 }}
                        alt="Courtbase logo"
                      />
                      <span className="font-title text-2xl">Court</span>
                      <span className="font-title text-2xl">base</span>
                    </NextLink>
                  </div>
                  <div className="hidden flex-1 md:flex md:h-16 md:items-center md:justify-between md:space-x-8">
                    <div>
                      <div
                        className={classNames(
                          "items-center",
                          user && "hidden lg:flex",
                        )}
                      >
                        {links.map((link) => (
                          <NextLink
                            key={link.href}
                            href={link.href}
                            className={classNames(
                              "px-4 py-2 text-xs font-medium uppercase tracking-wider text-gray-700",
                              ((pathname.startsWith(link.href) &&
                                link.href !== "/") ||
                                link.href === pathname) &&
                                "relative font-medium text-primary-500 after:absolute after:bottom-0.5 after:left-1/2 after:h-0.5 after:w-1/2 after:-translate-x-1/2 after:transform after:bg-primary-500/80 after:content-['']",
                            )}
                          >
                            {link.label}
                          </NextLink>
                        ))}
                      </div>
                    </div>
                    <div className="flex items-center">
                      {user &&
                        userLinks.map((link) => (
                          <NextLink
                            key={link.href}
                            href={link.href}
                            className={classNames(
                              "px-4 py-2 text-xs font-medium uppercase tracking-wider text-gray-700",
                              ((pathname.startsWith(link.href) &&
                                link.href !== "/") ||
                                link.href === pathname) &&
                                "relative font-medium text-primary-500 after:absolute after:bottom-0.5 after:left-1/2 after:h-0.5 after:w-1/2 after:-translate-x-1/2 after:transform after:bg-primary-500/80 after:content-['']",
                            )}
                          >
                            {link.label}
                          </NextLink>
                        ))}
                      <div className="flex pl-2 pr-2">
                        {i18n.language === "sl" && (
                          <NextLink href={asPath} locale="en" passHref>
                            <GB
                              title="English"
                              className="h-5 w-7 rounded-sm rounded-md border border-white ring-1 ring-gray-300"
                            />
                          </NextLink>
                        )}
                        {i18n.language === "en" && (
                          <NextLink href={asPath} locale="sl" passHref>
                            <SI
                              title="Slovenščina"
                              className="h-5 w-7 rounded-sm rounded-md border border-white ring-1 ring-gray-300"
                            />
                          </NextLink>
                        )}
                      </div>
                      <div className="hidden md:flex md:items-center">
                        {!aUser && !user && !loading && (
                          <div>
                            <NextLink
                              className="rounded-lg bg-primary-500 py-2 px-5 font-medium text-sm text-white"
                              href="/auth/login?returnTo=/login"
                            >
                              {t("login")}
                            </NextLink>
                          </div>
                        )}
                        {aUser && !user && !loading && (
                          <NextLink
                            className="rounded-lg bg-primary-500 py-2 px-5 text-white text-sm shadow-sm"
                            href="/onboarding/create-profile"
                          >
                            {t("create-profile")}
                          </NextLink>
                        )}
                        {/* Profile dropdown */}
                        {user && (
                          <Menu as="div" className="relative ml-3">
                            <div>
                              <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                                <span className="sr-only">Open user menu</span>
                                <Avatar user={user} size="medium" />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {aUser && organization && (
                                  <NextLink href="/dashboard/schedule">
                                    <MenuItem>
                                      <AdjustmentsHorizontalIcon className="mr-2 h-4 w-4" />
                                      {t("dashboard")}
                                    </MenuItem>
                                  </NextLink>
                                )}
                                <MenuItem onClick={signOut}>
                                  <ArrowRightOnRectangleIcon className="h-4 w-4 text-red-400" />{" "}
                                  <span className="text-red-400">
                                    {t("logout")}
                                  </span>
                                </MenuItem>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 pb-3 pt-2">
                {links.map((link) => (
                  <Disclosure.Button key={link.href} className="block py-1">
                    <NextLink
                      key={link.href}
                      href={link.href}
                      className={classNames(
                        "px-4 py-2 font-title text-xs uppercase tracking-wider text-gray-700",
                        ((pathname.startsWith(link.href) &&
                          link.href !== "/") ||
                          link.href === pathname) &&
                          "font-medium text-primary-500",
                      )}
                    >
                      {link.label}
                    </NextLink>
                  </Disclosure.Button>
                ))}
                {user &&
                  userLinks.map((link) => (
                    <Disclosure.Button key={link.href} className="block py-1">
                      <NextLink
                        key={link.href}
                        href={link.href}
                        className={classNames(
                          "px-4 py-2 font-title text-xs uppercase tracking-wider text-gray-700",
                          ((pathname.startsWith(link.href) &&
                            link.href !== "/") ||
                            link.href === pathname) &&
                            "font-medium text-primary-500",
                        )}
                      >
                        {link.label}
                      </NextLink>
                    </Disclosure.Button>
                  ))}
              </div>
              <div className="flex items-center justify-between p-4">
                {!user && (
                  <div>
                    <NextLink
                      className="text-normal rounded-lg bg-primary-500 py-2 px-5 font-medium text-white"
                      href="/auth/login?returnTo=/login"
                    >
                      {t("login")}
                    </NextLink>
                  </div>
                )}
                {i18n.language === "sl" && (
                  <NextLink href={asPath} locale="en" passHref>
                    <GB
                      title="English"
                      className="h-5 w-7 rounded-sm rounded-md border border-white ring-1 ring-gray-300"
                    />
                  </NextLink>
                )}
                {i18n.language === "en" && (
                  <NextLink href={asPath} locale="sl" passHref>
                    <SI
                      title="Slovenščina"
                      className="h-5 w-7 rounded-sm rounded-md border border-white ring-1 ring-gray-300"
                    />
                  </NextLink>
                )}
              </div>
              {user && (
                <div className="border-t border-gray-200 pb-3 pt-4">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <Avatar user={user} size="medium" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">
                        {user.firstName
                          ? `${user.firstName} ${user.lastName}`
                          : user.email}
                      </div>
                      <div className="text-sm font-medium text-gray-500">
                        {user.email}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
